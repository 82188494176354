.categories {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & h3 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    align-self: flex-start;
    margin-bottom: 0;
    padding: 0.6rem 1.4rem;
    letter-spacing: 0;
  }

  & ul {
    border: 1px solid rgb(240, 240, 240);
  }

  & li {
    transition: all 0.5s ease;
  }

  & a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    font-weight: 600;
    border-bottom: 1px solid rgb(240, 240, 240);
    color: #000;
  }

  & i {
    margin-right: 2rem;
    font-size: 1.3rem;
    color: #ccc;
  }
}

@media (max-width: 576px) {
  .categories a {
    padding: 15px 20px;
  }
}
