.footer {
  display: flex;
  justify-content: center;
}

.header {
  margin-bottom: 2.5rem;
}

.review {
  display: flex;
  margin-bottom: 5rem;

  &:nth-of-type(n) {
    padding-right: 2.5rem;
  }

  &:nth-of-type(n + 1) {
    padding-left: 2.5rem;
  }
}

.photo {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #fff;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(40, 40, 50);
    opacity: 0.3;
    transition: all 0.3s ease;
  }
}

.content {
  padding-left: 30px;

  & h4 {
    width: 70%;
  }

  & span {
    display: block;
    margin-top: 2rem;
    font-size: 1.1rem;
    font-weight: 500;

    & b {
      font-weight: inherit;
      margin-right: 5px;
    }
  }
}

@media (max-width: 992px) {
  .review {
    &:nth-of-type(n) {
      padding-right: 0;
    }

    &:nth-of-type(n + 1) {
      padding-left: 0;
    }
  }
}

@media (max-width: 768px) {
  .header {
    margin-bottom: 0;
  }

  .review {
    margin-bottom: 2rem;
  }
}

@media (max-width: 576px) {
  .review {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
  }

  .content {
    padding-left: 0;
    display: flex;
    flex-direction: column;

    & h4 {
      align-self: center;
      margin-top: 2rem;
      margin-bottom: 1rem;
      width: 100%;
    }

    & span {
      margin-top: 1rem;
    }
  }
}
