.article {
  display: flex;
  align-items: center;

  & p:not(:last-of-type) {
    margin-bottom: 3rem;
  }
}

.wrapper {
  align-self: center;
  padding-left: 5rem;
}

.footer {
  display: flex;
  align-items: center;
  border-top: 1px solid;
  margin-top: 4rem;
  padding-top: 4rem;

  & h4 {
    margin-bottom: 0;
    margin-top: 1rem;
  }

  & a {
    margin-right: 3rem;
  }

  & span {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  & figure {
    display: block;
    width: 7rem;
    height: 1px;
    margin: 0 2rem;
  }

  & h5 {
    margin-bottom: 0;
  }

  & p {
    max-width: 350px;
  }

  & span {
    margin-top: 3rem;
  }
}

.image {
  width: 100%;
  height: 50rem;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5rem;
  transform: translateY(-20rem);
  margin-bottom: -20rem;
  padding: 4rem 5rem;
  min-height: 25rem;

  & span.stars {
    display: block;
    margin-bottom: 1.5rem;
  }

  & i {
    font-size: 2rem;
    color: #fff;

    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }
  }

  & .client {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    & img {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      margin-right: 2rem;
    }

    & .name {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 2px;
    }

    & .job {
      text-transform: uppercase;
    }
  }
}

@media (max-width: 1200px) {
  .image {
    width: 80%;
  }

  .card {
    padding: 3rem 4rem;
  }

  .wrapper {
    padding-left: 4rem;
  }

  .header {
    margin-bottom: 2.5rem;
  }

  .footer {
    margin-top: 3.5rem;
    padding-top: 3.5rem;
  }

  .article p:not(:last-of-type) {
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 992px) {
  .image {
    width: 90%;
  }

  .card {
    padding: 3rem;
  }

  .wrapper {
    padding-left: 2rem;
  }

  .header {
    margin-bottom: 1rem;
  }

  .footer {
    margin-top: 2rem;
    padding-top: 3.5rem;
  }

  .article p:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 768px) {
  .image {
    width: 100%;
    height: 30rem;
  }

  .card {
    padding: 3rem;
  }

  .wrapper {
    padding-left: 0;
  }

  .header {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .footer {
    margin-top: 3rem;
    padding-top: 3.5rem;
  }

  .article p:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 576px) {
  .image {
    width: 100%;
    height: 30rem;
  }

  .card {
    display: none;
  }

  .header {
    margin-top: 2rem;
    margin-bottom: 2rem;

    & figure {
      width: 3rem;
    }
  }

  .footer {
    margin-top: 3rem;
    padding-top: 3rem;
  }
}
