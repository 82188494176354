.link {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 80%;
  cursor: pointer;

}

.active {
  color: #E05105;
  text-decoration: underline;
  text-decoration-style: solid;

}
