.footer {
    border-top: 1px solid #fff;
    transform: translateY(-3rem);
}

.wrapper {
    margin-top: 4rem;
}

.step {
    position: relative;
    height: 100%;
    border: 1px solid #fff;
    border-bottom: transparent;
    padding: 3rem;
    padding-bottom: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & .number {
        padding: 0 2rem;
        font-size: 7rem;
        font-weight: 300;
        transform: translateY(-100%);
        background: rgb(40, 40, 50);
        line-height: 1;
        margin-bottom: -4rem;
    }

    & p {
        margin-bottom: 1rem;
    }

    & .btn {
        margin-top: auto;
        align-self: center;
        transform: translateY(50%);
        z-index: 1;

        & a {
            cursor: default;
        }
    }

    & .info {
        transition: all .5s ease;
        opacity: 1;
        width: 100%;
    }

    & .list {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        transform: translate(-50%, -50%);
        font-size: 1.2rem;
        opacity: 0;
        transition: all .5s ease;

        & li {
            display: flex;
            margin: 1.5rem 0;
        }

        & i {
            font-size: 1.5rem;
            margin-right: 1.5rem;
            transform: translateY(2px);
        }
    }
}

@media (max-width: 768px) {
    .wrapper {
        margin-bottom: 4rem;
    }

    .step {
        border-bottom: 1px solid #fff;
    }

    .footer {
        display: none;
    }
}

@media (max-width: 576px) {
    .wrapper {
        margin-top: 3rem;
    }
}