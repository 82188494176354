.slide {
  width: 100%;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 600px;
  transform: translateY(0px);
  & i {
    font-size: 3rem;
    line-height: 0;
    cursor: pointer;
  }

  & img {
    width: 40%;
    margin-bottom: 3rem;
    margin-top: 20px;
  }
}

.intro {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & h1 {
    font-size: 4.5rem;
    font-weight: 200;
    line-height: 1.4;
    margin-bottom: 2rem;
    width: 100%;
  }

  & p {
    margin-bottom: 4rem;
    line-height: 2.2;
    width: 100%;
    font: normal 18px/30px Helvetica Neue;
  }
}

@media (max-width: 992px) {
  .slide {
    height: 700px;
    transform: translateY(20px);
  }

  .intro {
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .slide {
    height: 600px;
  }

  .intro {
    max-width: 400px;

    & h1 {
      font-size: 3rem;
      font-weight: 300;
    }

    & p {
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 576px) {
  .slide {
    height: 500px;
    transform: translateY(40px);

    & i {

    }
  }

  .intro {
    max-width: 80%;
  }
}
