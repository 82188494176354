.description {
  flex-basis: 100% !important;
  margin-bottom: 5rem;
  color: #ccc;
  font-size: 1.2rem;
  line-height: 2;
}

.user_photo {
  position: relative;
  height: 400px;
  margin-right: 3rem;

  & i {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 2rem;
    color: #fff;
    font-size: 3rem;
  }
}

.heading {
  display: inline-block;
  font-size: 2rem;
  letter-spacing: 1px;
  border: 1px solid #666;
  padding: 2rem 4rem;
  font-weight: 500;
}

.skill {
  position: relative;
  margin-right: 2rem;

  &>div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  &:not(:last-of-type) {
    margin-bottom: 2.5rem;
  }

  &:first-of-type {
    margin-top: 3.5rem;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    background-color: rgb(240, 240, 240);
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  & span {
    font-size: 1.1rem;
    font-weight: 500;
    text-transform: uppercase;

    &:nth-child(2) {
      position: relative;
      padding: 0.3rem 0.7rem;
      background: rgb(240, 240, 240);
      transform: translateY(-0.5rem);
      border-radius: 3px;
      font-weight: 600;

      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid rgb(240, 240, 240);
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  & figure {
    flex-basis: 100%;
    height: 5px;
    background: #000;
    margin-top: 5px;
  }
}

.project {
  display: flex;
  align-items: center;

  & a {
    color: #000;
  }

  &>a:last-of-type {
    margin-left: auto;
  }

  &:first-of-type {
    margin-top: 3rem;
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;
    border-bottom: 1px solid;
    padding-bottom: 2rem;
  }

  & figure {
    width: 8rem;
    height: 8rem;
    background: #ccc;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover i {
      transform: translateY(0);
      opacity: 1;
    }

    & i {
      transform: translateY(3rem);
      opacity: 0;
      transition: all 0.5s ease;
    }
  }

  & h5 {
    font-size: 1.3rem;
    font-weight: 400;
    margin: 0 2rem;
    transition: all 0.3s ease;
  }

  & i {
    padding: 1rem;
    border-radius: 50%;
    font-size: 1.5rem;
    background: #fff;
    transition: all 0.3s ease;

    &:hover {
      background: rgb(240, 240, 240) !important;
    }
  }
}

.stats {
  background: rgb(240, 240, 240);
  margin-bottom: 10rem;
  display: flex;
  padding: 5rem 0;

  &>div {
    flex-basis: 25%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  & i {
    font-size: 4rem;
    border-bottom: 1px solid #000;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    width: 50%;
  }

  & h1 {
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 1.5;
  }

  & span {
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;
  }
}

@media (max-width: 1200px) {
  .user_photo {
    height: 350px;
    margin-right: 2rem;
  }
}

@media (max-width: 992px) and (min-width: 769px) {
  .project {
    & figure {
      width: 6rem;
      height: 6rem;
    }

    & h5 {
      margin: 0 1rem;
    }
  }
}

@media (max-width: 992px) {
  .heading {
    padding: 1rem 2rem;
  }

  .user_photo {
    margin-right: 0;
  }

  .skill:first-of-type,
  .project:first-of-type {
    margin-top: 1.5rem;
  }

  .skill {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .column {
    &:not(:last-of-type) {
      margin-bottom: 4rem;
    }

    flex-grow: 1;
  }

  .description {
    margin-bottom: 3rem;
  }

  .stats {
    flex-wrap: wrap;
    padding: 2.5rem 0;

    &>div {
      flex-basis: 50%;
      margin: 2.5rem 0;
    }
  }
}

@media (max-width: 576px) {
  .stats {
    &>div {
      flex-basis: 100%;
    }

    & i {
      padding-bottom: 1.5rem;
      margin-bottom: 1rem;
    }
  }
}