.address_box {
  background: #fff;
  padding: 4.5rem;
  flex-grow: 1;
  text-align: center;
  transition: all 0.5s ease;

  & i {
    font-size: 3.5rem;
    color: #fff;

    &:hover {
      color: #000;
    }

  }

  & h3 {
    margin: 1.5rem 0;
    font-weight: 500;
  }

  & span {
    font-size: 1.2rem;
    color: #2B74A7;
    
  }

  & span::after{
    content: "\a";
    white-space: pre;
  }
}
